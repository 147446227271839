import { createAction, props } from '@ngrx/store';
import { ActionTypes } from './action-types';
import { OutlookCalendarEvent, OutlookCalendarParams } from '../get-outlook-calendar.service';

export const getOutlookCalendarAction = createAction(
  ActionTypes.GET_OUTLOOK_CALENDAR,
  props<{ payload: OutlookCalendarParams }>()
);

export const getOutlookCalendarSuccessAction = createAction(
  ActionTypes.GET_OUTLOOK_CALENDAR_SUCCESS,
  props<{ data: OutlookCalendarEvent[] }>()
);

export const getOutlookCalendarFailureAction = createAction(
  ActionTypes.GET_OUTLOOK_CALENDAR_FAILURE,
  props<{ error: any }>()
);
